import "react-loading-skeleton/dist/skeleton.css";

import { CurrentAccountIdProvider } from "@zapier/identity";
import {
  ColorsCSS as Colors,
  GlobalStylesProvider,
} from "@zapier/design-system";
import { GlobalStyle } from "../lib/theme";
import UserProvider from "lib/context/user-context";
import React, { Suspense } from "react";
import { PageErrorBoundary } from "components/PageErrorBoundary";
import { Toaster } from "lib/react-hot-toast";
import { DndProvider } from "lib/react-dnd";
import { MixpanelContextProvider } from "../observability/mixpanel";
import { TrackingProvider } from "observability";
import { ConfirmModalProvider } from "components/ModalConfirmation";
import { GlobalHead } from "components/GlobalHead";
import NextNProgress from "nextjs-progressbar";
import { MaintenancePage } from "components/MaintenancePage";
import { SkeletonTheme } from "components/SkeletonTheme";
import useMaintenance from "lib/hooks/useMaintenance";
import BuilderPageSpinner from "./BuilderPageSpinner";
import { SplitProvider } from "lib/context/split-context";
import { type InterfacesTheme, ThemeProvider } from "lib/theme/ThemeProvider";
import { ProjectAppearance } from "server/schemas/projects";

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  hideProgress?: boolean;
  omitNestedProviders?: boolean;
  skipPageViewEvent?: boolean;
  user?: any;
  /**
   * They are required on purpose.
   * We had quite a few bugs where we "forgot" to pass these values.
   * The intent behind having them required is to ensure we must _think_ about them when rendering this component.
   */
  projectId: string | undefined;
  interfacesTheme: InterfacesTheme | undefined | null;
  projectAppearance: ProjectAppearance | undefined | null;
};

export default function AppProvider({
  children,
  fallback = <BuilderPageSpinner />,
  hideProgress = false,
  omitNestedProviders = false,
  skipPageViewEvent = false,
  user,
  projectId,
  interfacesTheme,
  projectAppearance,
}: Props) {
  return (
    <>
      <GlobalHead />
      {!hideProgress && (
        <NextNProgress
          color={Colors.blue600}
          stopDelayMs={0}
          height={2}
          options={{ showSpinner: false }}
        />
      )}
      <GlobalStylesProvider isPlatform={true} isRebrand={true}>
        <ThemeProvider
          interfacesTheme={interfacesTheme}
          projectAppearance={projectAppearance}
          themeName={undefined}
        >
          <GlobalStyle />
          <SkeletonTheme>
            {!omitNestedProviders ? (
              <PageErrorBoundary>
                <Suspense fallback={fallback}>
                  <MaintenancePage useMaintenanceHook={useMaintenance}>
                    <CurrentAccountIdProvider>
                      <DndProvider>
                        <ConfirmModalProvider>
                          <UserProvider fallback={fallback} user={user}>
                            <SplitProvider
                              projectId={projectId}
                              fallback={fallback}
                            >
                              <TrackingProvider
                                skipPageViewEvent={skipPageViewEvent}
                              >
                                <MixpanelContextProvider>
                                  {children}
                                </MixpanelContextProvider>
                              </TrackingProvider>
                            </SplitProvider>
                          </UserProvider>
                        </ConfirmModalProvider>
                      </DndProvider>
                    </CurrentAccountIdProvider>
                  </MaintenancePage>
                </Suspense>
              </PageErrorBoundary>
            ) : (
              children
            )}
            <Toaster />
          </SkeletonTheme>
        </ThemeProvider>
      </GlobalStylesProvider>
    </>
  );
}
