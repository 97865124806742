import createClient from "openapi-react-query";
import { createClient as createInterfacesFetchClient } from "@zapier/api-clients/apis/interfaces";
import { createClient as createMonolithFetchClient } from "@zapier/api-clients/apis/monolith";
import { createClient as createTablesFetchClient } from "@zapier/api-clients/apis/tables";
import { createClient as createZslApiFetchClient } from "@zapier/api-clients/apis/zslapi";

export { type components as InterfacesAPI } from "@zapier/api-clients/apis/interfaces/types";

export const clients: {
  interfaces: ReturnType<typeof createInterfacesFetchClient>;
  monolith: ReturnType<typeof createMonolithFetchClient>;
  tables: ReturnType<typeof createTablesFetchClient>;
  zslapi: ReturnType<typeof createZslApiFetchClient>;
} = {
  interfaces: createInterfacesFetchClient({
    baseUrl: "/api/proxy/interfaces/",
  }),
  monolith: createMonolithFetchClient({
    baseUrl: "/api/proxy/monolith/",
  }),
  tables: createTablesFetchClient({
    baseUrl: "/api/proxy/tables/api/v0/",
  }),
  zslapi: createZslApiFetchClient({
    baseUrl: "/api/proxy/zslapi/",
  }),
} as const;

export const zrpc = {
  interfaces: createClient(clients.interfaces),
  monolith: createClient(clients.monolith),
  tables: createClient(clients.tables),
  zslapi: createClient(clients.zslapi),
} as const;
