import "zod-openapi/extend";

import { z } from "zod";
import {
  ContentSchema,
  ContentSchemaWithDefault,
} from "block-system/types/blocks";
import {
  ALPHANUMERIC_DASHES_SPACES_REGEX,
  PAGE_SLUG_REGEX,
  URL_PATTERN_REGEX,
} from "utils/regex";

export const MAX_BLOCKS_PER_PAGE = 80;

export const pageNameSchema = z
  .string()
  .min(1, "Name is required")
  .max(32)
  .regex(ALPHANUMERIC_DASHES_SPACES_REGEX, "Name contains invalid characters");

export const pageTitleSchema = z
  .string()
  .min(1, "Title should not be empty")
  .max(32);

export const pageSlugSchema = z
  .string()
  .min(1, "Required")
  .max(32)
  .regex(PAGE_SLUG_REGEX, "Must be lowercase and alphanumeric or dashes");

export const PageSchema = z
  .object({
    id: z.string().cuid(),
    name: z.string(),
    title: z.string(),
    navigationEnabled: z
      .boolean()
      .optional()
      .default(true)
      .openapi({ effectType: "input" }),
    slug: z.string(),
    content: ContentSchema,
    projectId: z.string().cuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
  })
  .openapi({ ref: "InterfacesPage" });

export const PageWithoutContentSchema = PageSchema.omit({ content: true });

export const listPagesSchema = z.object({
  projectId: z.string().cuid(),
});

export const createPageSchema = z.object({
  name: pageNameSchema,
  title: pageTitleSchema,
  projectId: z.string().cuid(),
  slug: pageSlugSchema.optional(),
  navigationEnabled: z
    .boolean()
    .optional()
    .default(true)
    .openapi({ effectType: "input" }),
});

export const updatePageSchema = z.object({
  id: z.string().cuid(),
  name: pageNameSchema.optional(),
  title: z.string().min(1, "Title should not be empty").max(32).optional(),
  slug: pageSlugSchema.optional(),
  navigationEnabled: z
    .boolean()
    .optional()
    .default(true)
    .openapi({ effectType: "input" }),
  content: ContentSchema.optional(),
});

export const getPageSchema = z.object({
  id: z.string().cuid(),
});

export const pagePublicSchema = z.object({
  id: z.string().cuid(),
  projectId: z.string().cuid(),
  name: z.string(),
  slug: z.string(),
  title: z.string(),
  navigationEnabled: z
    .boolean()
    .optional()
    .default(false)
    .openapi({ effectType: "input" }),
  content: ContentSchemaWithDefault,
  createdAt: z
    .date()
    .transform((val) => val.toISOString())
    .pipe(z.string().datetime()),
  updatedAt: z
    .date()
    .transform((val) => val.toISOString())
    .pipe(z.string().datetime()),
});

export const navigationPageItemSchema = z.object({
  id: z.string().cuid(),
  isHomepage: z.boolean(),
  title: z.string(),
  url: z.string(),
});

export type NavigationPageItem = z.infer<typeof navigationPageItemSchema>;

export const navigationPageLinkSchema = z
  .object({
    type: z.literal("page"),
    pageId: z.string(),
    opensInNewTab: z.boolean().optional(),
  })
  .strict();

export const navigationCustomLinkSchema = z
  .object({
    type: z.literal("url"),
    title: z.string().min(1, "Title is required"),
    url: z.string().regex(URL_PATTERN_REGEX, "Invalid URL"),
    opensInNewTab: z.boolean().optional(),
  })
  .strict();

export const navigationLinkSchema = z.discriminatedUnion("type", [
  navigationPageLinkSchema,
  navigationCustomLinkSchema,
]);
export const navigationLinksSchema = navigationLinkSchema.array();

export type NavigationLink = z.infer<typeof navigationLinkSchema>;
export type NavigationLinks = NavigationLink[];
export type PagePublicSchema = z.infer<typeof pagePublicSchema>;
