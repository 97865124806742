import { ZodSchema, z } from "zod";
import {
  AccountPlan as PrismaAccountPlan,
  PaidFeature as PrismaPaidFeature,
  ProjectAuthType,
} from "@prisma/client";
import { AccountEntitlementsResponse } from "../services/zapier/types/types";

import { components } from "@zapier/api-clients/apis/permissions/types";

export type Operation = {
  type: string;
  path: string;
  validator: ZodSchema;
};

// When adding a new feature, add it to the PaidFeature enum in schema.prisma
export const paidFeatures = z.nativeEnum(PrismaPaidFeature);
export type PaidFeature = z.infer<typeof paidFeatures>;

export const accountPlan = z.nativeEnum(PrismaAccountPlan);
export type AccountPlan = z.infer<typeof accountPlan>;

export const premiumFeatures: PaidFeature[] = [
  "customColors",
  "branding",
  "embedding",
  "analytics",
  "customTokenLength",

  "passwordProtect",
  "managedUsers",

  "chatBotDataSources",
  "conditionalLogic",
  "fileSizeLimit10mb",
  "embedBlock",
  "layoutBlock",
  "stripePayment",
  "extendedFileUploads",

  /**
   * Stytch-related project authorization
   */
  "stytchAuth",
  "projectMagicLinkAuth",
];

export const advancedFeatures: PaidFeature[] = [
  ...premiumFeatures,
  "customDomain",
  "dynamicFilters",
  "hideZapierButton",
  "fileSizeLimit25mb",

  /**
   * Stytch-related project authorization
   */
  "projectSignInWithGoogleAuth",
  "projectApprovedDomainsAuth",
];

export const teamAccountFeatures: PaidFeature[] = ["collaborators"];

export const allFeatures: PaidFeature[] = Array.from(
  new Set(premiumFeatures.concat(advancedFeatures, teamAccountFeatures))
);

export type AuthenticatedUser = {
  id: string;
  zapierId: number;
  accountIds: number[];
  currentAccountId: number;
  isStaff: boolean;
  entitlements: AccountEntitlementsResponse;
};

export type AuthContext = {
  user?: AuthenticatedUser;
};

/**
 * These are the various asset IDs that can be used both in FGA permissions checks and in non-FGA authz checks.
 *
 * TODO: https://zapierorg.atlassian.net/browse/INTRFCS-3677
 * We need a centralized, tRPC / OpenAPI agnostic way to get these values from the request
 */
export type AuthzInput = {
  projectId?: string;
  blockId?: string;
  formId?: string;
  pageId?: string;
  blockActionId?: string;
  projectAuth?: {
    type?: ProjectAuthType;
  };
};

export type OperationAuthzRequest = {
  operationId: string;
  context: AuthContext;
  input: AuthzInput;
  permissionConfig?: PermissionConfig;
};

export type AssetType = components["schemas"]["AssetType"];
export type PermissionLevel = components["schemas"]["PermissionLevel"];

export type PermissionConfig = {
  assetType: AssetType; // Should always be "interface"

  permissionLevel: PermissionLevel;

  /**
   * For now, this will always be a project ID but as we get more granular with the FGA auth
   * model, we can add functions for getting different asset IDs
   */
  getAssetId: (input: AuthzInput) => Promise<string | null>;

  trpcCheckEnabled?: boolean;
  openAPICheckEnabled?: boolean;
};
